import { DELETE, FORMDATA, GET, POST, PUT } from "./APIservice"

export const getEmpList = () => {
    return GET('/admin/employee');
}

export const getEmpDetails = (id) => {
    return GET(`/admin/employee/${id}`)
}

export const createUpdateEmp = (data,setError) => {
    return POST('/admin/employee',data,setError)
}

export const deleteEmp = (id) => {
    return DELETE(`/admin/employee/${id}`)
}

//  ----------- Lead  -------------

export const createLead = (data,setError) => {
   return POST('/admin-employee/lead',data,setError);
}

export const getLeadList  = () => {
    return GET('/admin-employee/lead');
}

export const getLeadInfo = (id) => {
    return GET(`admin-employee/lead/${id}`);
}

export const deleteLead = (id) => {
    return DELETE(`/admin-employee/lead/${id}`)
}

export const getSales = () => {
    return GET('/admin/sales');
}
//  Admin list ---------

export const getAgentList = () => {
    return GET('/admin-employee/agent')
}
export const deactivateUserApi = (id) =>{
    return DELETE(`/admin-employee/agent/${id}`)
}

export const assignSalesToAgent = (data,setError) => {
    return POST('/admin/agent',data,setError)
}

// ---------- query 

export const getQueryListAtAdmin = () => {
    return GET('/admin-employee-user/query')
}

// ------------- Quotes

export const createQuoteByAdminApi = (data,setError) => {
    return FORMDATA('/admin-employee/quote',data,setError)
}

// ----------- designation

export const getAllDesignationApi = () => {
    return GET('/admin/designation');
}

export const createDesinationApi = (data,setError) => {
    return POST('/admin/designation',data,setError);
}

export const updatePnrApi = (data,setError) => {
    return POST('/admin-employee/update-pnr',data,setError);
}

export const DeleteDesinationApi = (id) => {
    return DELETE(`/admin/designation/${id}`);
}
export const getAllDesignationByDeptApi = (id) => {
    return GET('/admin/designation-department/'+id);
}


// ------------- Flight list ----------- 

export const getFlightListApiAdmin = () => {
    return GET('/admin-employee/flight');
}
export const getFlightListApi = () => {
    return GET('/admin-employee/flight');
}

export const DELETE_FLIGHT = (id) => {
    return DELETE(`/admin-employee/flight/${id}`);
}

export const CREATE_FLIGHT = (data,setError) => {
    return POST('/admin-employee/flight',data,setError);
}
export const UPDATE_FLIGHT = (id,data,setError) => {
    return PUT(`/admin-employee/flight/${id}`,data,setError);
}


// -------------------- Invoice ---------------


export const getSubGroupMasterApi = () => {
    return GET('/admin-employee/sub-group-master');
}

export const getPrimaryGroupMasterApi = () => {
    return GET('/admin-employee/primary-group-master');
}

export const getTaxSlabApi = () => {
    return GET('/admin-employee/txt-slab');
}

export const getCreditnoteApi = (id) => {
    return GET(`/admin-employee/creditnote/${id}`);
}
export const getCreditnotechragesApi = (id) => {
    return GET(`/admin-employee/invoice-charges/${id}`);
}

export const createCrnApi = (id,data,setError) => {
    return POST(`/admin-employee/creditnote/${id}`,data,setError);
}

export const getLedgerListApi = () => {
    return GET('/admin-employee/ledger');
}

export const getHsnCodeApi = () => {
    return GET('/admin-employee/hsn-code');
}

export const getChargesApi = () => {
    return GET('/admin-employee/charge');
}

export const getChargesByBranchApi = (id) => {
    return GET(`/admin-employee/charge/${id}`);
}

export const createChargeApi = (data,setError) => {
    return POST('/admin-employee/charge',data,setError);
}

export const generateInvoice = (data) => {
    return POST('/admin-employee/generate-invoice',data);
}

// ------- Dashboard --------------

export const getDashboardApi = () => {
    return GET('/admin-employee/dashboardv2');
}


// -------- Designation --------

export const moduleMenuAPi = (id) => {
    return GET(`/admin/menu-list/${id}`);
}

export const moduleMenuMapAPi = (id) => {
    return GET(`/admin/menu-list-map/${id}`);
}

// ---------finance ---

export const getofflinePaymentApi = () => {
    return GET('/admin-employee/offline-payments');
}

export const getpreLoginTransactionApi = () => {
    return GET('/admin-employee/prelogin-transaction');
}

export const verifyOfflinePayApi = (data,setError) => {
    return PUT('/admin-employee/offline-payments',data,setError);
}

export const getBranchesApi = () => {
    return GET('/admin-employee/branch');
}


export const getInvoicesList = () => {
    return GET('/admin-employee/invoices');
}

export const createAdditionalInvoiceApi = (data,setError) => {
    return POST('/admin-employee/extra-invoices',data,setError);
}

export const genrateInvoiceAPi = (id) => {
    return GET(`/admin-employee/manual-invoice/${id}`);
}

export const cancelInvoiceApi = (id) => {
    return PUT(`/admin-employee/cancel-invoice/${id}`);
}


