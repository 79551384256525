import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { alpha, styled } from '@mui/material/styles'
import {
  getMapcollection,
  makePaymentquery,
  postMappedApi,
  userMakePayment,
  userMakePaymentdetails,
  userMakeverifyPayment,
} from '../../../../services/configService'
import { Payment, Send } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { SnackbarContext } from '../../../../context/Snackbar'
import { baseURL } from '../../../../services/APIservice'
import { load } from '@cashfreepayments/cashfree-js'
import useAuth from '../../../../hooks/useAuth'
import Makepaymentsuccess from '../../payment/Makepaymentsuccess'
import { Modal } from '@mui/material'
import './style.css'
const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'white',
    width: '100%',
    backgroundColor: 'lightslategrey',
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#61699f',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  // color: 'skyblue',
}

export default function Makepaymentlisting() {
  const { auth } = useAuth()
  const navigate = useNavigate()
  const [data, setData] = React.useState([])
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)

  const [isChecked, setIsChecked] = React.useState([])
  const [error, setError] = React.useState('')
  const [editable, SetEditable] = React.useState(false)
  const [mappedRowData, setMappedRowdata] = React.useState([])
  const [paymentdetails, setPaymentdetails] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [loader, setLoader] = React.useState(false)
  // const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    window.location.reload()
  }

  console.log('authhhhhhhh', auth)

  async function getData() {
    try {
      const res = await makePaymentquery()
      if (res.status !== 204) {
        setData(res.data)
        console.log('res.data', res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  console.log('isChecked', isChecked)

  const handleCheckboxChange = (event, row) => {
    console.log('event.target.checked', event.target.checked)
    setIsChecked((prev) => {
      let newChecked = [...prev]

      if (event.target.checked) {
        const newObject = {
          ...row,
          ded_amt: row.ded_amt ? row.ded_amt : 0,
          amount: row.balance_amount,
          tds_amt: row.paid_tds_amt ? row.paid_tds_amt : row.tds_amt,
          payment_type: row.amount_type,
        }
        newChecked.push(newObject)
      } else {
        newChecked = newChecked.filter((item) => item.p_id !== row.p_id)
      }

      return newChecked
    })
    SetEditable(true)
  }

  const CustomFooter = () => {
    let cashfree
    let insitialzeSDK = async function () {
      cashfree = await load({
        mode: 'production',
      })
    }

    const amounts =
      Object.values(isChecked).map((item) => item.total_amount) || []
    let totalSum = 0
    for (let i = 0; i < amounts.length; i++) {
      totalSum += amounts[i]
    }

    const getSessionId = async (id) => {
      const sendData = {
        amount: totalSum,
        is_checked: isChecked,
      }
      try {
        let res = await userMakePayment(sendData, setError)

        if (res !== 204 && res !== 406) {
          return res
        } else if (res === 406) {
          setLoader(false)
        }
      } catch (error) {
        console.log()
      }
    }

    const verifyPayment = async (id, order_id) => {
      const sendData = {
        order_id: order_id,
        auth_id: auth.id,
      }

      // const callback_url = `${baseURL}/agent/mapped-verify-payment`

      try {
        let res = await userMakeverifyPayment(sendData)

        if (res.data.is_captured === true) {
          console.log('success', res)
          setSeverity('success')
          setMes('Payment Successfull Done')
          setOn(true)
          setOpen(true)
          setLoader(false)
          setPaymentdetails(res.data)
          const data = {
            customer_id: auth.id,
            cheque_bank_name: res.data.payment_method.card
              ? res.data.payment_method?.card?.card_bank_name
              : res.data.payment_method?.upi
              ? res.data.payment_method?.upi?.upi_id
              : res.data.payment_method?.app
              ? res.data.payment_method?.app.provider
              : res.data.payment_method?.netbanking
              ? res.data.payment_method?.netbanking?.netbanking_bank_name
              : res.data.payment_mode,
            cheque_no: res.data.payment_id,
            payment_mode_type: 4,
            query_details: isChecked?.sort(
              (a, b) => a.amount_type - b.amount_type
            ),
          }

          let response = await userMakePaymentdetails(data)

          console.log('res', response)
          if (response !== 204 && response !== 406) {
            await getData()
            setIsChecked([])
          }

          // setSessionStorage(res, 'agentpaymentres')
          // navigate('/payment-success/:id', { replace: true })
        } else if (res.data.is_captured === false) {
          setSeverity('error')
          setMes('Payment Failed')
          setOn(true)
        }
      } catch (error) {
        console.log(error)
        // showAlert('Payment failed.', 'error')
        // setSpinner(false)
      }
    }
    // console.log("heloassas",paymentdetails, paymentdetails.payment_method?.card.card_bank_name, paymentdetails.payment_mode,paymentdetails.payment_id);

    const cashFreeRecharge = async (amount, name, id) => {
      setLoader(true)
      insitialzeSDK()
      // setOpen(false)
      try {
        const response = await getSessionId(id)
        // let insitialzeSDK = async function () {
        //   cashfree = await load({
        //     mode: 'sandbox',
        //   })
        // }

        // insitialzeSDK()
        let checkoutOptions = {
          paymentSessionId: response?.data?.payment_session_id,
          redirectTarget: '_modal',
        }
        console.log('response?.data?.payment_session_id', response)
        cashfree.checkout(checkoutOptions).then((paymentStatus) => {
          // console.log(paymentStatus);
          if (paymentStatus.paymentDetails) {
            verifyPayment(
              id,
              response?.data?.order_id
              // bookingId,
              // storedflightFromCode,
              // storedflightToCode,
              // billingdetails.name
            )
          } else if (paymentStatus.error) {
            verifyPayment(
              id,
              response?.data?.order_id
              // bookingId,
              // storedflightFromCode,
              // storedflightToCode,
              // billingdetails.name
            )
            // showAlert('Payment Failed.', 'error')
            // setSpinner(false)
            console.log('Payment failed. Not verifying.')
          }
          // setCashFreeAmount('')
        })
      } catch (error) {
        console.log(error)
      }
    }

    return (
      <Grid
        container
        gap={2}
        paddingLeft={2}
        alignItems={'center'}
        justifyContent={'space-between'}
        borderTop={'1px solid #e5e5e5'}
      >
        <Grid item display={'flex'} gap={1}>
          <Stack direction={'row'} spacing={1} margin={1.5}></Stack>
        </Grid>
        <Grid item display={'flex'} gap={1}>
          <Stack direction={'row'} spacing={1} margin={1.5}>
            <TextField
              size='small'
              className='form_style'
              label='Total Amount'
              // sx={{ width: '20%' }}
              value={totalSum}
            />

            <Button
              className='form_style'
              variant='outlined'
              startIcon={<Payment />}
              disabled={
                isChecked.length === 0 || data?.length === 0 ? true : false
              }
              onClick={cashFreeRecharge}
              color='success'
            >
              Pay Now
            </Button>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  React.useEffect(() => {
    getData()
  }, [])
  React.useEffect(() => {
    console.log('useEffect is running')
    if (error.amount_type) {
      setSeverity('error')
      setMes(
        "Select amount type in order: token, advance, final."
      )
      setOn(true)
    }
  }, [error])

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 75,
      cellClassName: 'actions',
      renderCell: (params) => {
        return (
          <Checkbox
            // checked={isChecked[params.row.p_id]}
            onChange={(event) => handleCheckboxChange(event, params.row)}
          />
        )
      },
    },
    {
      field: 'query_code',
      headerName: 'Query Code',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'due_date',
      headerName: 'Due Date',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return params.row.due_date
          ? dayjs(params.row.due_date).format('DD-MM-YYYY')
          : 'N.A'
      },
    },
    {
      field: 'amount_type',
      headerName: 'Amount Type',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return `${
          params.row.amount_type === 1
            ? 'Token Amount'
            : params.row.amount_type === 2
            ? 'Advance Amount'
            : 'Final Amount'
        }`
      },
    },

    {
      field: 'trans_id',
      headerName: 'Transaction Id',
      flex: 1,
      minWidth: 150,
    },

    {
      field: 'total_amount',
      headerName: 'Total Amt',
      flex: 1,
      minWidth: 150,
    },
  ]

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <Modal
        open={loader}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='loader-container'>
          <div class='dot-spinner'>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
          </div>
          <p
            style={{
              marginLeft: '10px',
              fontWeight: '500',
              marginTop: '10px',
            }}
          >
            Please wait!!
          </p>
        </div>
      </Modal>

      <StripedDataGrid
        rows={data?.map((item, index) => ({
          ...item,
          id: index + 1,
        }))}
        getRowId={(row) => row.id}
        columns={columns}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar, footer: CustomFooter }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        disableRowSelectionOnClick
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Makepaymentsuccess paymentdetails={paymentdetails} />
        </Box>
      </Modal>
    </Box>
  )
}
