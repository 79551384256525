import * as React from 'react'
import { alpha, styled } from '@mui/material/styles'
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  gridClasses,
  GridToolbar,
} from '@mui/x-data-grid'
import {
  getCustomerbooking,
  getPreBookingPassengers,
  getPrebookingPassengerUpdate,
} from '../../../services/configService'
import {
  ArrowRightAlt,
  Cancel,
  Download,
  DownloadDone,
  Edit,
  Info,
  Save,
  SyncAlt,
  Upload,
} from '@mui/icons-material'
import {
  Button,
  Tooltip,
  Grid,
  Popover,
  Typography,
  Card,
  IconButton,
  CardContent,
  TextField,
  Modal,
  Box,
  Chip,
} from '@mui/material'
import {
  currencyFormatter,
  downloadAttachment,
  handleChangeGlobal,
} from '../../../utils/common'
import TableMenu from '../../../components/table_modal/TableMenu'
import dayjs from 'dayjs'
import { SnackbarContext } from '../../../context/Snackbar'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TableModal from '../../../components/table_modal/TableModal'
import Deletecard from '../../../components/card/Deletecard'
import UploadModal from '../../agent/dashboard/my_booking/UploadModal'
import { unparse } from 'papaparse'
import {
  generateInvoice,
  getFlightListApi,
} from '../../../services/admin.configService'
import { CalendarIcon } from '@mui/x-date-pickers'
import PrePassengerList from './PrePassengerList'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'white',
    width: '100%',
    backgroundColor: 'lightslategrey',
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
}
const date = new Date()
date.toLocaleDateString()

export default function StripedGrid() {
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  const [bookings, setBookings] = React.useState([])
  const [updateData, setUpdateData] = React.useState({})
  const [updateDate, setUpdateDate] = React.useState({})
  const [passenger, setPassenger] = React.useState({})
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openmodal, setOpenmodal] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [cancelbooking, setCancelbooking] = React.useState({})
  const [error, setError] = React.useState('')
  const [flightList, setFlightList] = React.useState('')
  const [openchangedatemodal, setOpenchangedatemodal] = React.useState(false)
  const [showPassengerModal, setShowPassengerModal] = React.useState(false)

  const [showPnrModal, setShowPnrModal] = React.useState(false)
  const [queryId, setQueryId] = React.useState('')

  const handlePnrCloseModal = () => {
    setShowPnrModal(false)
  }

  const handleOpenmodal = (id, row) => {
    setOpenmodal(true)
    setPassenger(row)
    // console.log('id', id, row)
  }

  const handleDeleteOpenModal = (row) => {
    setShowModal(true)
    setCancelbooking(row)
  }
  const handleDownloadInvoice = (row) => {
    console.log('link', row)
    downloadAttachment(row.pdf, 'Invoice')
  }
  const handleClosemodal = () => setOpenmodal(false)
  const handleChangedateClosemodal = () => setOpenchangedatemodal(false)
  const handleDeleteClodemodal = () => setShowModal(false)
  async function getBookingList() {
    try {
      const res = await getCustomerbooking()
      if (res !== 204 && res !== 401) {
        setBookings(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleGenrateInvoice = async (id) => {
    const sendData = {
      id: id,
      is_pre_login: 1,
    }
    try {
      const res = await generateInvoice(sendData)
      if (res !== 204 && res !== 401) {
        setSeverity('success')
        setMes('invoice created')
        setOn(true)
        getBookingList()
      }
    } catch (error) {
      setSeverity('error')
      setMes('Error')
      setOn(true)
    }
  }

  const handleChangedateOpenmodal = (row) => {
    setOpenchangedatemodal(true)
    setUpdateDate(row)
    console.log('id', row.destination_code)
  }

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget)
    setUpdateData(row)
    const passengers = row.passengers.map((item) => item)
    console.log(passengers)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  // const handleUpdate = async () => {
  //   // setOpen(true)

  //   const sendData = {
  //     passenger_id: passenger.id,
  //     first_name: passenger?.first_name,
  //     middle_name: passenger?.middle_name,
  //     last_name: passenger?.last_name,
  //   }

  //   try {
  //     const res = await getPrebookingPassengerUpdate(sendData)
  //     console.log('res', res)
  //     if (res !== 406) {
  //       setOpenmodal(false)

  //       setOn(true)
  //       setSeverity('success')
  //       setMes('updated successfully')
  //       getBookingList()
  //     }
  //     // const res = await handlePreloginpayment(sendData.total_fare, sendData.billing_phone, sendData.billing_name)
  //   } catch (error) {
  //     console.log(error)
  //   } finally {
  //   }
  // }

  const handleUpdate = async () => {
    // setOpen(true)

    const sendData = {
      passenger_id: passenger.id,
      first_name: passenger?.first_name,
      middle_name: passenger?.middle_name,
      last_name: passenger?.last_name,
    }

    try {
      const res = await getPrebookingPassengerUpdate(sendData)
      console.log('Response:', res)

      if (res !== 406) {
        console.log('Condition met, updating...')
        setOpenmodal(false)
        setOn(true)
        setSeverity('success')
        setMes('Updated successfully')
        handleClose()
        getBookingList()
      } else {
        console.log('Response was 406, condition not met')
      }
    } catch (error) {
      console.log(error)
    } finally {
      console.log('Finished handling update')
    }
  }

  const disableDateFrom = (calDate) => {
    const x = flightList.filter(
      (item) =>
        item.flight_departure_from == updateDate?.origin_code &&
        item.flight_departure_to == updateDate?.destination_code
    )[0]?.flight_available
    const arr = []
    x?.forEach((item) => {
      if (item.is_available) {
        return arr.push(item.date)
      }
    })
    var z = dayjs(calDate).format('DD-MM-YYYY')
    return !arr?.includes(z)
  }

  const disableDateTo = (calDate) => {
    const x = flightList.filter(
      (item) =>
        item.flight_departure_from == updateDate?.destination_code &&
        item.flight_departure_to == updateDate?.origin_code
    )[0]?.flight_available

    const arr = []

    x?.forEach((item) => {
      if (item.is_available) {
        return arr.push(item.date)
      }
    })
    var z = dayjs(calDate).format('DD-MM-YYYY')

    return !arr?.includes(z)
  }

  const handleChangedates = async () => {
    // setOpen(true)

    const sendData = {
      booking_id: updateDate.id,
      departure_date: dayjs(updateDate?.departure_date).format('YYYY-MM-DD'),
      is_cancelled: 0,
      return_date: dayjs(updateDate?.return_date).format('YYYY-MM-DD'),
    }

    try {
      const res = await getPrebookingPassengerUpdate(sendData)
      console.log('res', res)
      if (res !== 406) {
        setOpenchangedatemodal(false)
        getBookingList()
        setOn(true)
        setSeverity('success')
        setMes('Date updated successfully')
      }
      // const res = await handlePreloginpayment(sendData.total_fare, sendData.billing_phone, sendData.billing_name)
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  const handleUploadOpenModal = (id) => {
    setQueryId(id)
    setShowPnrModal(true)
    // setTotalPassenger(passenger);
    // setShowModalType(type)
  }

  const handleBookingcancel = async () => {
    const sendData = {
      booking_id: cancelbooking.id,
      departure_date: dayjs(cancelbooking?.departure_date).format('YYYY-MM-DD'),
      is_cancelled: 1,
      return_date: dayjs(cancelbooking?.return_date).format('YYYY-MM-DD'),
    }

    try {
      const res = await getPrebookingPassengerUpdate(sendData)
      console.log('res', res)
      if (res !== 406) {
        setShowModal(false)
        getBookingList()
        setOn(true)
        setSeverity('success')
        setMes('Booking Cancel successfully')
      }
      // const res = await handlePreloginpayment(sendData.total_fare, sendData.billing_phone, sendData.billing_name)
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  const getFlightList = async () => {
    try {
      const res = await getFlightListApi()
      if (res !== 204 && res !== 401) {
        setFlightList(res?.data)
      }
    } catch (error) {
      alert(error.message)
    }
  }

  const handleOpenPassengerModal = async (id) => {
    setShowPassengerModal(true)
    setQueryId(id)
  }

  const handleDownloadPassenger = async (passengers) => {
    try {
      console.log('passenger', passengers)
      if (passengers) {
        const csvData = passengers?.map((item) => ({
          booking_id: item?.pre_booking_id,
          passenger_id: item.id,
          first_name: item.first_name,
          middle_name: item?.middle_name,
          last_name: item?.last_name,
          dob: item?.dob,
          mobile_code: '91',
          mobile_no: item.mobile_no,
          email: item.email_id,
          pan_no: item?.pan_no,
          passport_expiry_date: new Date(
            item?.passport_expiry
          ).toLocaleDateString(),
          passport_no: item.passport_no ? item.passport_no : '',
          pnr_no: item?.pnr,
          ticket_status: !item?.is_cancelled ? 'Active' : 'Cancelled',
        }))
        const csv = unparse(csvData)
        const blob = new Blob([csv], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'passenger_list.csv'
        document.body.appendChild(link)
        link.click()
        document.removeChild(link)
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleClosePassengerModal = () => {
    setShowPassengerModal(false)
  }

  React.useEffect(() => {
    getBookingList()
    getFlightList()
  }, [])

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'billing_name', headerName: 'Full Name', flex: 1, minWidth: 120 },
    {
      field: 'billing_phone',
      headerName: 'Phone Number',
      flex: 1,
      minWidth: 110,
    },
    { field: 'billing_email', headerName: 'Email ID', flex: 1, minWidth: 160 },
    {
      field: 'orginDest',
      headerName: 'Orgin & Destination',
      minWidth: 150,
      disableColumnMenu: 'true',
      flex: 1,
      renderCell: (params) => (
        <div>
          <Tooltip
            title={
              <div style={{ fontSize: '13px' }}>
                <div>
                  {params?.row?.is_return_trip === 1
                    ? 'Round trip'
                    : params?.row?.is_return_trip === 0
                    ? 'One-way trip'
                    : 'Multi-City'}
                </div>
                <div>{params?.row?.origin_code}</div>
                <div>{params?.row?.destination_code}</div>
              </div>
            }
          >
            <div
              style={{
                display: 'flex',
                gap: '6px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ fontWeight: '600' }}>
                {params?.row?.origin_code}
              </div>
              {params?.row?.is_return_trip == 1 ? (
                <SyncAlt />
              ) : (
                <ArrowRightAlt />
              )}
              <div style={{ fontWeight: '600' }}>
                {params?.row?.destination_code}
              </div>
              {params?.row?.is_return_trip == 3 && <ArrowRightAlt />}
              <div style={{ fontWeight: '600' }}>
                {params?.row?.return_destination}
              </div>
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'created_date',
      headerName: 'Booking Date',
      minWidth: 104,
      disableColumnMenu: true,
      flex: 1,
      valueFormatter: (params) => {
        return dayjs(params.value).format('DD-MM-YYYY')
      },
    },
    {
      field: 'departure_date',
      headerName: 'Departure Date',
      minWidth: 104,
      disableColumnMenu: true,
      flex: 1,
      valueFormatter: (params) => {
        return dayjs(params.value).format('DD-MM-YYYY')
      },
    },
    {
      field: 'return_date',
      headerName: 'Return Date',
      minWidth: 104,
      disableColumnMenu: true,
      flex: 1,
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value).format('DD-MM-YYYY') : 'N.A'
      },
    },
    // Add more dummy columns here
    {
      field: 'total_fare',
      type: 'actions',
      headerName: 'Amount',
      minWidth: 130,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <Button
            variant='text'
            // onClick={() =>
            //   handleOpenModal(row?.query_id, row?.total_fare, row?.pdf)
            // }
          >
            <p style={{ borderRadius: '0px' }}>
              {currencyFormatter.format(row?.total_fare)}
            </p>
          </Button>,
        ]
      },
    },
    {
      field: 'is_cancelled',
      headerName: 'Status',
      width: 170,
      renderCell: (params) => {
        const label =
          params.value == 2
            ? 'Partially Cancelled'
            : params.value == 1
            ? 'Cancelled'
            : 'Confirmed'
        const color =
          params.value == 2
            ? 'warning'
            : params.value == 1
            ? 'error'
            : 'success'
        return <Chip label={label} color={color} variant='outlined' />
      },
    },
    {
      type: 'actions',
      headerName: 'Passenger',
      minWidth: 120,
      cellClassName: 'actions',
      flex: 1,
      getActions: ({ row, id }) => {
        return [
          <>
            {!row.is_cancelled || true ? (
              <Button
                // onClick={()=>handleSaveSession(row)}
                onClick={(e) => handleClick(e, row)}
                variant='outlined'
                color='success'
                startIcon={<Edit />}
              >
                Edit
              </Button>
            ) : (
              <Tooltip
                title={`Adult: ${row.total_adult}, Child: ${row.total_child}, Infant: ${row.total_infant}`}
              >
                <IconButton>
                  <Info />
                </IconButton>
              </Tooltip>
            )}
          </>,
        ]
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 75,
      cellClassName: 'actions',
      getActions: ({ row }) => {
        return [
          <>
            {!row.is_cancelled || true ? (
              <TableMenu
                data={[
                  {
                    id: 1,
                    title: 'Change Dates',
                    icon: <CalendarIcon fontSize='small' color='success' />,
                    disable: row?.is_cancelled == 1 ?  true :  false,
                    fn: () => handleChangedateOpenmodal(row),
                  },
                  {
                    id: 2,
                    title: 'Passenger List',
                    icon: <Download fontSize='small' color='error' />,
                    // disable: !row?.pdf ? true : false,
                    fn: () => handleDownloadPassenger(row.passengers),
                  },
                  {
                    id: 3,
                    title: 'Download Invoice',
                    icon: <Download fontSize='small' color='info' />,
                    disable: row?.pdf ? false : true,
                    fn: () => handleDownloadInvoice(row),
                  },
                  {
                    id: 4,
                    title: 'Upload PNR',
                    icon: <Upload fontSize='small' color='info' />,
                    disable: row?.is_cancelled == 1 ?  true :  false,
                    fn: () => handleUploadOpenModal(row?.id, 'pnr'),
                  },
                  {
                    id: 20,
                    title: "Cancel Passenger Ticket's",
                    icon: <Cancel fontSize='small' color='warning' />,
                    disable: row?.is_cancelled == 1 ? true : false,
                    fn: () => handleOpenPassengerModal(row?.id),
                  },
                  {
                    id: 5,
                    title: 'Create Invoice',
                    icon: <Edit fontSize='small' color='success' />,
                    disable: row?.pdf  || row?.is_cancelled == 1 ? true : false,
                    fn: () => handleGenrateInvoice(row?.id),
                  },
                ]}
              />
            ) : (
              <Tooltip title='Cancelled'>
                <IconButton>
                  <Info />
                </IconButton>
              </Tooltip>
            )}
          </>,
        ]
      },
    },
  ]

  const passengercolumns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'first_name', headerName: 'First Name', minWidth: 150 },
    { field: 'middle_name', headerName: 'Middle Name', minWidth: 150 },
    { field: 'last_name', headerName: 'Last Name', minWidth: 150 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 110,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        return [
          <div>
            <GridActionsCellItem
              icon={<Edit />}
              label='Edit'
              style={{
                padding: '5px',
                backgroundColor: '#2a6ebd',
                color: 'white',
              }}
              onClick={() => handleOpenmodal(id, row)}
              color='inherit'
            />
          </div>,
        ]
      },
    },
  ]

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 9rem)',
        backgroundColor: 'white',
      }}
    >
      <StripedDataGrid
        loading={false} // Set loading to false or true depending on your needs
        rows={bookings?.map((item, index) => ({
          ...item,

          sno: index + 1,
        }))}
        columns={columns}
        getRowClassName={(params) =>
          params.row.is_cancelled == 1
            ? 'cancel'
            : params.indexRelativeToCurrentPage % 2 === 0
            ? 'even'
            : 'odd'
        }
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Card sx={{ padding: '20px', background: '#edf1f4' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Typography
                id='modal-modal-title'
                sx={{ fontSize: '19px', fontWeight: 510 }}
              >
                Passengers
              </Typography>
              <Typography
                id='modal-modal-description'
                sx={{ fontSize: '15px', color: '#A9A9A9' }}
              >
                Details of all passengers.
              </Typography>
            </div>
            <IconButton aria-label='delete' onClick={handleClose}>
              <Cancel />
            </IconButton>
          </div>

          <CardContent
            sx={{ borderRadius: 2, mt: 1, padding: 0, background: 'white' }}
          >
            <StripedDataGrid
              loading={false} // Set loading to false or true depending on your needs
              rows={updateData.passengers?.map((item, index) => ({
                ...item,

                sno: index + 1,
              }))}
              columns={passengercolumns}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
            />
          </CardContent>
        </Card>
      </Popover>
      <Modal
        open={openmodal}
        // onClose={handleClosemodal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Typography
                id='modal-modal-title'
                sx={{ fontSize: '19px', fontWeight: 510 }}
              >
                Passenger details
              </Typography>
              <Typography
                id='modal-modal-description'
                sx={{ fontSize: '15px', color: '#A9A9A9' }}
              >
                Edit & Update passenger Name.
              </Typography>
            </div>
            <IconButton aria-label='delete' onClick={handleClosemodal}>
              <Cancel />
            </IconButton>
          </div>

          <CardContent sx={{ background: '#edf1f4', borderRadius: 2, mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <TextField
                  id='outlined-basic'
                  label='First Name'
                  variant='outlined'
                  name='first_name'
                  size='small'
                  value={passenger?.first_name}
                  onChange={(e) =>
                    handleChangeGlobal(
                      e,
                      setPassenger,

                      'first_name'
                    )
                  }
                  fullWidth
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  id='outlined-basic'
                  label='Middle Name'
                  variant='outlined'
                  size='small'
                  name='middle_name'
                  value={passenger?.middle_name}
                  // defaultValue={passenger?.middle_name}
                  onChange={(e) =>
                    handleChangeGlobal(
                      e,
                      setPassenger,

                      'middle_name'
                    )
                  }
                  fullWidth
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  id='outlined-basic'
                  label='Last Name'
                  variant='outlined'
                  size='small'
                  name='last_name'
                  value={passenger?.last_name}
                  // defaultValue={passenger?.last_name}
                  onChange={(e) =>
                    handleChangeGlobal(
                      e,
                      setPassenger,

                      'last_name'
                    )
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardContent>
          <Button
            onClick={handleUpdate}
            startIcon={<Save />}
            variant='contained'
            sx={{ background: '#01357E', float: 'right', mt: 1 }}
          >
            save
          </Button>
        </Card>
      </Modal>
      <Modal
        open={openchangedatemodal}
        onClose={handleChangedateClosemodal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Typography
                id='modal-modal-title'
                sx={{ fontSize: '19px', fontWeight: 510 }}
              >
                Details
              </Typography>
              <Typography
                id='modal-modal-description'
                sx={{ fontSize: '15px', color: '#A9A9A9' }}
              >
                Change & Update Trip Dates.
              </Typography>
            </div>
            <IconButton
              aria-label='delete'
              onClick={handleChangedateClosemodal}
            >
              <Cancel />
            </IconButton>
          </div>

          <CardContent sx={{ background: '#edf1f4', borderRadius: 2, mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item md={updateDate?.is_return_trip === 1 ? 6 : 12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label='Departure Date'
                    // className='form_style'
                    format='DD-MM-YYYY'
                    name='startingfrom'
                    value={dayjs(updateDate?.departure_date)}
                    onChange={(newDate) => {
                      setUpdateDate((prevDetails) => ({
                        ...prevDetails,
                        departure_date: newDate,
                      }))
                    }}
                    disablePast
                    // error={error && error['starting_from']}
                    slotProps={{
                      textField: {
                        size: 'small',
                      },
                    }}
                    shouldDisableDate={(date) => disableDateFrom(date)}
                  //   minDate={dayjs(updateDate?.departure_date)}
                  />
                </LocalizationProvider>
              </Grid>

              {updateDate?.is_return_trip === 1 && (
                <Grid item md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label='Return Date'
                      // className='form_style'
                      format='DD-MM-YYYY'
                      name='return_date'
                      value={dayjs(updateDate?.return_date)}
                      onChange={(newDate) => {
                        setUpdateDate((prevDetails) => ({
                          ...prevDetails,
                          return_date: newDate,
                        }))
                      }}
                      disablePast
                      // error={error && error['starting_from']}
                      slotProps={{
                        textField: {
                          size: 'small',
                        },
                      }}
                      shouldDisableDate={(date) => disableDateTo(date)}
                     //  minDate={dayjs(updateDate?.return_date)}
                    />
                  </LocalizationProvider>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Button
            onClick={handleChangedates}
            startIcon={<Save />}
            variant='contained'
            sx={{ background: '#01357E', float: 'right', mt: 1 }}
          >
            save
          </Button>
        </Card>
      </Modal>
      <TableModal
        handleCloseModal={handleDeleteClodemodal}
        handleShowModal={showModal}
      >
        <Deletecard
          subheading={'cancel this booking'}
          btnText={'Sure'}
          onClickBack={handleDeleteClodemodal}
          onClickSubmit={handleBookingcancel}
          // isLoading={isLoading}
        />
      </TableModal>
      <TableModal
        handleCloseModal={handlePnrCloseModal}
        handleShowModal={showPnrModal}
      >
        <UploadModal
          setShow={setShowPnrModal}
          query_id={queryId}
          setQueryId={setQueryId}
          setError={setError}
        />
      </TableModal>
      <TableModal
        handleShowModal={showPassengerModal}
        handleCloseModal={handleClosePassengerModal}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
          }}
        >
          <PrePassengerList
            booking_id={queryId}
            getBookingList={getBookingList}
            handleClosePassengerModal={handleClosePassengerModal}
          />
        </div>
      </TableModal>
    </div>
  )
}
