import { Delete } from '@mui/icons-material'
import { AuthPOST, DELETE, FORMDATA, GET, POST, PUT } from './APIservice'

// ----------------- GET --------------------------

export const getAgentProfile = async () => {
  return GET(`/agent/profile`)
}

export const userAuthServer = async () => {
  return GET('/auth/user-authorize')
}

export const logout = async () => {
  return GET('/auth/logout')
}

export const getQuote = async () => {
  return GET('/admin-employee-user/query')
}

export const getDepartment = async () => {
  return GET('/admin/department')
}

export const getCountry = async () => {
  return GET('/auth/country')
}

export const getState = async (data) => {
  return GET(`/auth/state/${data}`)
}

export const getCity = async (data) => {
  return GET(`/auth/city/${data}`)
}

export const getAddressListApi = async (id) => {
  return GET(`/admin-employee-user/address-list/${id}`)
}

export const getPaymentBreakDownApi = async (id) => {
  return GET(`/admin-employee-user/paymentdetails/${id}`)
}

export const getLeadstatus = async () => {
  return GET('/admin-employee/lead-status')
}

export const getLeadsource = async () => {
  return GET('/admin-employee/lead-source')
}

export const getAgent = async () => {
  return GET('/admin-employee/agent')
}

export const getBankdetails = async () => {
  return GET('/admin-employee-user/bank-details')
}

export const getAirport = async () => {
  return GET('/auth/airport')
}

export const getQuotesDetailsApi = async (id) => {
  return GET(`/admin-employee-user/quote/${id}`)
}

export const getFlightInventorydetailsApi = async (id) => {
  return GET(`/admin-employee/inventory-flight-details/${id}`)
}

export const getPassengerInventorydetailsApi = async (id) => {
  return GET(`/admin-employee/passenger-list-byflight/${id}`)
}

export const getPassengerListApi = async (id) => {
  return GET(`/admin-employee-user/passenger-query/${id}`)
}

export const getQuerymessage = async (id) => {
  return GET(`/admin-employee-user/query-message/${id}`)
}

export const getBookingSummary = async () => {
  return GET(`/admin-employee-user/bookingsummary`)
}
export const getNotification = async () => {
  return GET(`/admin-employee-user/notification`)
}

export const clearNotificationApi = async () => {
  return DELETE(`/admin-employee-user/notification`)
}

export const readNotification = async (data) => {
  return PUT(`/admin-employee-user/notification`, data)
}

export const getSettingss = async (id) => {
  return GET(`/admin-employee/settings/${id}`)
}

export const getCustomerbooking = async () => {
  return GET(`/pre-login/booking-details`)
}

export const getChargehead = async () => {
  return GET(`/admin-employee/charge-head`)
}

export const makePaymentquery = async () => {
  return GET(`/agent/get_unpaid_query`)
}

export const getSeatinventoryreport = async () => {
  return GET(`/admin-employee/seat-inventory`)
}

export const getReceiptCollection = async () => {
  return GET(`/admin-employee/collection`)
}
export const getSMTPListing = async () => {
  return GET(`/smtp`)
}

export const getSeatcategorylist = async () => {
  return GET(`/seatcategorylist`)
}
export const getTicketlist = async () => {
  return GET(`/ticket`)
}
export const getUserTicketlist = async (id) => {
  return GET(`/userticket/${id}`)
}
export const getorgticket = async (id) => {
  return GET(`/orgticket/${id}`)
}
export const getOrgTicketlist = async (data) => {
  return GET(`/orgticket`, data)
}

export const getMapcollection = async (id, rid) => {
  return GET(`/admin-employee/get_unpaid_query_by_agent?cid=${id}&rid=${rid}`)
}

export const getMappedUpdate = async (cid, rid) => {
  return GET(`/admin-employee/get-mapped-data?cid=${cid}&rid=${rid}`)
}

export const getCollaboratorListApi = async (id) => {
  return GET(`/getcc/${id}`)
}
export const getOrgUserListApi = async (id) => {
  return GET(`/orguser/${id}`)
}
export const getTermsAndConditionlist = async (id) => {
  return GET(`/admin-employee-user/terms-conditions/${id}`)
}
export const getSummaryOfParticularTicket = async (id) => {
  return GET(`/summary/${id}`)
}
export const getAllCollaboratorListApi = async () => {
  return GET(`/allcc`)
}
export const getAllUserListApi = async () => {
  return GET(`/users`)
}
export const getAllOrganizationListApi = async () => {
  return GET(`/organizationlist`)
}
export const getAllPaymentLinks = async () => {
  return GET(`/admin-employee/payment-links`)
}
export const getTermsandcondition = async () => {
  return GET(`/admin/terms-conditions`)
}

export const getPrebookingPassengerUpdate = async (data) => {
  return PUT(`/pre-login/booking-details`, data)
}

export const getDisabledate = async (data) => {
  return PUT(`/admin-employee/charge-head`, data)
}

//------------------ POST -----------------------------

export const signupGenrateOtp = async (data, setError) => {
  return AuthPOST('/auth/signup-generate-otp', data, setError)
}

export const updateProfile = async (data) => {
  return POST('/userAuthorization', data)
}

export const getEmailOtp = async (data, setError) => {
  return AuthPOST(`/auth/login-otp-generate`, data, setError)
}

export const loginOtpValidation = async (data, setError) => {
  return AuthPOST('/auth/login-with-otp-validate', data, setError)
}

export const createAgentProfile = async (data, setError) => {
  return AuthPOST('/auth/signup', data, setError)
}

export const agentLogin = async (data, setError) => {
  return AuthPOST('/auth/login', data, setError)
}
export const agentPayment = async (data) => {
  return POST('/agent/create/orderId', data)
}
export const userMakePayment = async (data, setError) => {
  return POST('/agent/mapped-initiate-payment', data, setError)
}
export const userMakeverifyPayment = async (data, setError) => {
  return POST('/agent/mapped-verify-payment', data, setError)
}
export const userMakePaymentdetails = async (data, setError) => {
  return POST('/admin-employee/agentmakepayment', data, setError)
}
export const userImapThread = async (id, setError) => {
  return GET(`/conversation/${id}`, setError)
}
export const getOrgNote = async (id, setError) => {
  return GET(`/orgnote/${id}`, setError)
}
export const getUserNote = async (id, setError) => {
  return GET(`/usernote/${id}`, setError)
}

export const preloginPayment = async (data) => {
  return POST('/pre-login/initiate-order', data)
}

export const subUserCreation = async (data) => {
  return POST('/agentcontactcreate', data)
}

export const subUserList = async (data) => {
  return POST('/agentcontactlist', data)
}

export const mailDataById = async (id) => {
  return GET(`/admin-employee/quotes-mail-services/${id}`)
}

export const subUserUpdating = async (data) => {
  return POST('/agentcontactupdate', data)
}

export const subUserDelete = async (data) => {
  return POST('/agentcontactsoftdelete', data)
}

export const verifyPayment = async (data) => {
  return POST('/agent/verify-payment', data)
}

export const offlinePayApi = async (data, setError) => {
  return POST('/agent/payment-pay', data, setError)
}

export const onewayTicket = async (data) => {
  return POST('/agent/one-way-ticket', data)
}
export const imapInternalNote = async (data) => {
  return FORMDATA('/internalnotes', data)
}

export const imapPostReplyApi = async (data, setError) => {
  return FORMDATA('/postreply', data, setError)
}
export const noteReply = async (data, setError) => {
  return POST('/createnote', data, setError)
}
export const imapCreateCollaboratorApi = async (data, setError) => {
  return POST('/createcollaborator', data, setError)
}
export const imapCreateOrgUserApi = async (data, setError) => {
  return POST('/createorguser', data, setError)
}

export const createPaymentLinkApi = async (data, setError) => {
  return POST('/admin-employee/generate-payment-link', data, setError)
}
export const deletePaymentLinkApi = async (id) => {
  return POST(`/admin-employee/cancel-payment-link/${id}`)
}

export const createTermandCondition = async (data) => {
  return POST(`/admin/terms-conditions`, data)
}

export const forgetPassOtpValidate = async (data, setError) => {
  return AuthPOST('/auth/forget-pass-otp-validate', data, setError)
}

export const createQuery = async (data, setError) => {
  return POST('/admin-employee-user/query', data, setError)
}

export const addPassengersApi = async (data, setError) => {
  return POST('/agent/passenger', data, setError)
}

export const addPassengersCSVApi = async (data, setError) => {
  return POST('/agent/passengercsv', data, setError)
}

export const getAcknowledgmenetApi = async (data) => {
  return POST('/admin-employee-user/acknowledgement', data)
}
export const getSaveSettingss = async (data) => {
  return POST('/admin-employee/settings', data)
}

export const getPreLogindetails = async (data, setError) => {
  return POST('/pre-login/booking-details', data, setError)
}

export const getCreateChargeHead = async (data, setError) => {
  return POST('/admin-employee/charge-head', data, setError)
}

export const getPreLoginInitiatePayment = async (data) => {
  return POST('/pre-login/initiate-cashfree-order', data)
}

export const getPreLoginVerifyPayment = async (data) => {
  return POST('/pre-login/verify-cashfree-payment', data)
}

export const queryMessage = async (data) => {
  return FORMDATA(`/admin-employee-user/query-message`, data)
}

export const receiptCreate = async (data) => {
  return FORMDATA(`/admin-employee/collection`, data)
}

export const postInquires = async (data) => {
  return POST(`/auth/inquires`, data)
}

export const postMappedApi = async (data, setError) => {
  return POST(`/admin-employee/mrmapping`, data, setError)
}

export const postUpdateMappedApi = async (data, setError) => {
  return POST(`/admin-employee/unmapping`, data, setError)
}

export const postUpdateseatrateandavailability = async (data, setError) => {
  return POST(`/updateseatrateandavailability`, data, setError)
}

export const allUnmappedApi = async (data) => {
  return POST(`/admin-employee/allunmapping`, data)
}
export const createOrganizationApi = async (data) => {
  return POST(`/createorganization`, data)
}
export const createSMTPApi = async (data, setError) => {
  return POST(`/createsmtp`, data, setError)
}
export const acceptedTncApi = async (data, setError) => {
  return POST(`/admin-employee-user/accept-tnc`, data, setError)
}

// -------------------- PUT ----------------------

export const updateAgentProfile = async (data, setError) => {
  return PUT('/agent/profile', data, setError)
}

export const passwordUpdate = async (data) => {
  return PUT('/auth/forget-password', data)
}

export const updateQuoteStatusAPi = async (data, id, setError) => {
  return PUT(`/admin-employee-user/query-status/${id}`, data, setError)
}

export const holdDateExtend = async (data, id, setError) => {
  return PUT(`/admin-employee-user/request-hold-date/${id}`, data, setError)
}

export const updateHoldDateAndTime = async (data, id, setError) => {
  return PUT(`/admin-employee/quote-date-reschedule/${id}`, data, setError)
}

export const changeProfilePicApi = async (data, setError) => {
  return FORMDATA(`/admin-employee-user/profile-pic`, data, setError, 'PUT')
}

export const availableStatus = async (id, data) => {
  return PUT(`/admin-employee/flight-available/${id}`, data)
}

export const updateNoShow = async (id, data) => {
  return PUT(`/admin-employee/update-no-show/${id}`, data)
}

export const receiptUpdate = async (id, data) => {
  return PUT(`/admin-employee/collection/${id}`, data)
}

export const updateCollaborator = async (data) => {
  return PUT('/updatethreadcc', data)
}
export const updateTicketStatus = async (data) => {
  return PUT('/ticketstatus', data)
}
export const updateSMTP = async (data) => {
  return PUT('/updatesmtp', data)
}

export const mappedUpdate = async (data) => {
  return POST(`/admin-employee/mrmapping`, data)
}
export const updateTermandCondition = async (data) => {
  return PUT(`/admin/terms-conditions`, data)
}
export const cancelQuote = async (id) => {
  return PUT(`/admin-employee/cancel-accepted-quote/${id}`)
}
export const updateOrgeNoteReply = async (data, setError) => {
  return PUT(`/updatenote`, data, setError)
}

// -------------------- DELETE ----------------------

export const receiptDelete = async (id) => {
  return DELETE(`/admin-employee/collection/${id}`)
}

export const deleteCollaborator = async (id) => {
  return DELETE(`/deletethreadcc/${id}`)
}
export const deleteOrg = async (data) => {
  return POST(`/deleteorganization`, data)
}
export const deleteorgNote = async (data) => {
  return POST(`/deletenote`, data)
}
export const deleteSMTP = async (id) => {
  return DELETE(`/deletesmtp/${id}`)
}
export const deleteTermandCondition = async (id) => {
  return DELETE(`/admin/terms-conditions/${id}`)
}

export const getSeatRenderAvailability = async (data, setError) => {
  return POST(`/getseatrateandavailability`, data, setError)
}

export const createSeatAvailability = async (data, setError) => {
  return POST(`/seatrateandavailability`, data, setError)
}

export const totalPriceRateCalculate = async (data, setError) => {
  return POST(`/ratecalculate`, data, setError)
}

export const quoteMailService = async (data, error) => {
  return POST(`/admin-employee/quotes-mail-services`, data, error)
}

export const cancelQueryTicketByPassenger = async (data, error) => {
  return POST(`/admin-employee-user/cancel-ticket-by-passenger`, data, error)
}

export const generateSalesOrderApi = async (id) => {
  return GET(`/admin-employee/sales_order_generate/${id}`)
}

export const getPreBookingPassengers = async (id) => {
  return GET(`/pre-login/passenger-list/${id}`)
}

export const cancelPreBookingTicketByPassenger = async (data, error) => {
  return POST(`/pre-login/cancel-booking-by-passenger`, data, error)
}
